import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppStateService } from '../app-state.service';
import { QueueService } from './queue.service';
import { LoadedAccessionInfo } from './loaded-accession.class';

@Injectable({
  providedIn: 'root',
})
export class QueueAccessionResolver {
  constructor(private queueService: QueueService, private appStateService: AppStateService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LoadedAccessionInfo> {
    const queueType = route.paramMap.get('queueType');
    const workQueue = this.appStateService.queues.find((wq) => wq.type === queueType);
    const validQueue =
      workQueue && (workQueue.type === 'FULL' || workQueue.type === 'PARTIAL' || workQueue.type === 'PARTIAL_VERIFIED' || workQueue.type === 'PARTIAL_NEEDS_REVIEW');

    if (validQueue) {
      this.appStateService.workQueue = workQueue;
      return this.queueService.advanceQueue(workQueue._links.first);
    } else {
      return of(null);
    }
  }
}
