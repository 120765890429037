import { Component, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { KeyboardService, ModalContainerService } from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { AppStateService } from './app-state.service';
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { filter } from 'rxjs/internal/operators/filter';
import { InteractionStatus } from '@azure/msal-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  accession: Observable<Accession>;
  loggedIn = false;
  private onDestroy$ = new Subject<void>();

  @HostListener('document:keydown', ['$event'])
  onKeydown($evt: KeyboardEvent) {
    // Modals handle their own key events
    if (!!this.modalService.openModal) {
      return $evt;
    }

    // Prevent keyboard interactions while loading
    if (this.appStateService.loading) {
      this.keyboardService.preventDefaultAndPropagation($evt);
      return false;
    }

    if (!this.appStateService.loading) {
      this.keyboardService.handleAppKeydown($evt);
    }
  }

  constructor(
    private router: Router,
    private title: Title,
    public keyboardService: KeyboardService,
    public appStateService: AppStateService,
    private modalService: ModalContainerService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
  ) {
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart) {
        this.appStateService.loading = true;
        this.appStateService.accession = null;
        this.appStateService.accessionHeader = null;
      } else if (e instanceof NavigationEnd || (e instanceof NavigationError && this.appStateService.accession)) {
        this.appStateService.loading = false;
      }
    });

    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.broadcastService.inProgress$.pipe(
      filter(progress => {
        return progress === InteractionStatus.None;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    });
  }

  handleRefresh($event) {
    window.location.reload();
  }
}
