import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AccessionService as HeaderService, Link, populateLink } from '@lims-common-ux/lux';
import { Accession } from '../interfaces/accession';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { map, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from '../app-state.service';
import { Inject, Injectable } from '@angular/core';
import { PAGE_RESOURCE } from '../application-init.service';
import { PageResource } from '../interfaces/pageResource.interface';
import { Test } from '../interfaces/test.interface';
import { ServiceCategory } from '../interfaces/service-category.interface';
import { Assay } from '../interfaces/assay.interface';

@Injectable({
  providedIn: 'root',
})
export class AccessionService {
  private readonly accessionLink: Link;

  constructor(
    private http: HttpClient,
    private appState: AppStateService,
    private headerService: HeaderService,
    @Inject(PAGE_RESOURCE) resource: PageResource,
  ) {
    this.accessionLink = resource._links.accessions;
  }

  loadAccession(accessionId: string): Observable<Accession> {
    return this.loadAccessionFromHref(populateLink(this.accessionLink, { accessionId }));
  }

  loadAccessionHeader(accessionId: string): Observable<AccessionHeader> {
    return this.headerService.getAccession(accessionId).pipe(
      tap((accessionHeader: AccessionHeader) => {
        this.appState.accessionHeader = accessionHeader;
      }),
    );
  }

  setAccessionFromResponse(response): Accession {
    if (response.body) {
      response.body.version = response.headers.get('ETag');
    }

    this.appState.accession = response.body;

    return response.body;
  }

  // Only public it's used in the queue resolver. Should probably find a better way to express queue items.
  loadAccessionFromHref(href: string): Observable<Accession> {
    return this.http.get<Accession>(href, { observe: 'response' }).pipe(
      map((response) => {
        if (response.body) {
          // Set accession with updated version
          return this.setAccessionFromResponse(response);
        } else {
          // Clear the current accession state here?
          return null;
        }
      }),
    );
  }

  addTestComment(test: Test, commentId: string) {
    const url = populateLink(test._links.addComment, { commentId });

    return this.http
      .put(url, null, { headers: { 'If-Match': this.appState.accession.version }, observe: 'response' })
      .pipe(
        tap((response: any) => {
          this.appState.loading = true;
          this.setAccessionFromResponse(response);
        }),
      );
  }

  deleteTestComment(test: any, commentId: string) {
    const comment = test.comments.find((cmt) => cmt.id === commentId);
    const url = comment?._links?.removeComment?.href;

    return this.http
      .delete(url, { headers: { 'If-Match': this.appState.accession.version }, observe: 'response' })
      .pipe(
        switchMap((response: any) => {
          return this.loadAccession(this.appState.accession.accessionId);
        }),
      );
  }

  sortAccessionAssaysByServiceCategory(accession: Accession): Accession {
    const accessionServiceCategories = accession.serviceCategories.map((category: ServiceCategory) => {
      const categoryAssays = accession.assays.filter((assay: Assay) => {
        // Group accession assays by service category
        return assay.serviceCategoryCode === category.code;
      });
      // Sort assays by ordinal
      category.assays = this.sortByOrdinal(categoryAssays);
      category.expanded = true;
      return category;
    });
    // Sort service categories by ordinal
    accession.serviceCategories = this.sortByOrdinal(accessionServiceCategories);
    return accession;
  }

  sortByOrdinal(collectionWithOrdinal) {
    return collectionWithOrdinal.sort((a: ServiceCategory, b: ServiceCategory) => {
      if (a.goldListOrdinal < b.goldListOrdinal) {
        return -1;
      } else if (a.goldListOrdinal > b.goldListOrdinal) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
