import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Accession } from '../interfaces/accession';

@Injectable({
  providedIn: 'root',
})
export class ReleaseService {

  constructor(private http: HttpClient) {
  }

  release(accession: Accession, includedAssays: string[]) {
    if (includedAssays.length || accession.hasUnreleasedComments) {
      return this.http.post<Accession>(
        accession._links.release.href,
        { assays: includedAssays },
        { headers: { 'If-Match': accession.version } },
      );
    } else {
      console.warn('No assays included in Release');
    }
  }
}
