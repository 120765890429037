import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Accession } from '../interfaces/accession';
import { AppStateService } from '../app-state.service';
import { Router } from '@angular/router';
import { Assay, AssayStatus } from '../interfaces/assay.interface';
import {
  KeyboardAction,
  KeyboardService,
  Lab,
  LabNotesComponent,
  ModalContainerService,
  SnackbarComponent,
} from '@lims-common-ux/lux';
import { ReleaseService } from './release.service';
import { AccessionService } from './accession.service';
import { of, Subscription } from 'rxjs';
import { AssayComponent } from '../assay/assay.component';
import { ServiceCategory } from '../interfaces/service-category.interface';
import { TestsComponent } from '../test/tests/tests.component';
import { QueueService } from './queue.service';

@Component({
  selector: 'app-accession',
  templateUrl: './accession.component.html',
  styleUrls: ['./accession.component.scss', './comment-list.component.scss'],
})
export class AccessionComponent implements OnInit, OnDestroy {
  @ViewChild('snackbarComponent')
  snackbar: SnackbarComponent;

  @ViewChild('nextInQueueButton')
  nextInQueueButton: ElementRef;

  @ViewChildren('assay')
  assays: QueryList<AssayComponent>;

  @ViewChildren('lineItem')
  lineItems: QueryList<ElementRef>;

  @ViewChild('panelsAndProfiles')
  panelsAndProfiles: TestsComponent;

  @ViewChild('labNotes')
  labNotes: LabNotesComponent;

  accessionSub: Subscription;
  accession: Accession;
  lab: Lab;
  excludedAssays: Assay[] = [];
  releaseableAssays: Assay[] = [];
  // the currently focused assay, in app-state but then binded inform assay display
  selectedAssay: Assay;
  selectedAssaySub: Subscription;
  private focusFirstAssaySub: Subscription;

  private releaseAction = {
    name: 'release-accession',
    eventMatch: {
      key: 's',
      altKey: true,
      matcher: () => !this.modalService.openModal,
    },
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      // Wont be present if feature is off
      if (this.labNotes && this.labNotes.visible) {
        this.labNotes.addLabNote();
      } else {
        this.releaseAccession();
      }
    },
  } as KeyboardAction;

  // The action name here is defined in LUX
  private focusPanelProfileAction = {
    name: 'focus-panel-comment',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      if (!this.panelsAndProfiles || this.panelsAndProfiles.tests.length < 1) {
        return;
      }

      this.focusDetailViewComment();
    },
  } as KeyboardAction;

  private nextInQueueAction = {
    name: 'next-in-queue',
    eventMatch: {
      key: 'ArrowRight',
      altKey: true,
      matcher: () => !this.modalService.openModal,
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      this.handleNextInQueueAction();
    },
  } as KeyboardAction;

  private navigateLineItem = {
    name: 'navigate-line-item',
    eventMatch: {
      matcher: ($evt: KeyboardEvent) => {
        return $evt.altKey && ($evt.key === 'ArrowDown' || $evt.key === 'ArrowUp') && !this.modalService.openModal;
      },
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      this.handleLineItemNavigation($evt);
    },
  } as KeyboardAction;

  private focusFirstAssayAction: KeyboardAction = {
    name: 'assay-first-focus',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.focusFirstAssay();
    },
  };

  constructor(
    public appStateService: AppStateService,
    private accessionService: AccessionService,
    private releaseService: ReleaseService,
    private router: Router,
    private queueService: QueueService,
    private keyboardService: KeyboardService,
    private modalService: ModalContainerService
  ) {}

  ngOnInit(): void {
    // Update current accession data (comments, etc.)
    this.accessionSub = this.appStateService.accessionSub.subscribe((accession: Accession) => {
      if (this.appStateService.lab) {
        this.lab = this.appStateService.lab;
      }

      if (accession) {
        if (!this.accession || accession.accessionNumber !== this.accession.accessionNumber) {
          this.excludedAssays = [];
          this.appStateService.currentAssay = of(null);
        }

        this.accession = this.accessionService.sortAccessionAssaysByServiceCategory(accession);

        setTimeout(() => {
          if (this.assays?.first && !this.selectedAssay) {
            this.assays.first.selectAssay();
          } else if (this.selectedAssay) {
            this.assays.forEach((assayCmp) => {
              if (assayCmp.assay.code === this.selectedAssay.code) {
                assayCmp.selectAssay();
              }
            });
          }
        }, 0);
      } else {
        this.accession = accession;
        this.excludedAssays = [];
        this.releaseableAssays = [];
      }
    });

    this.selectedAssaySub = this.appStateService.currentAssay.subscribe((currentAssay) => {
      this.selectedAssay = currentAssay;
    });

    this.keyboardService.addActions([
      this.releaseAction,
      this.focusPanelProfileAction,
      this.nextInQueueAction,
      this.navigateLineItem,
      this.focusFirstAssayAction,
    ]);

    this.focusFirstAssaySub = this.appStateService.focusFirstAssayEvent.subscribe(() => {
      this.focusFirstAssay();
    });
  }

  focusDetailViewComment() {
    this.panelsAndProfiles.focusFirstTestItem();
  }

  handleLineItemNavigation($event) {
    let focusItemIndex = 0;
    const activeElement = document.activeElement;
    const focusDirection = $event.key === 'ArrowDown' ? 1 : -1;

    // Find the index of the next navigable item
    this.lineItems.forEach((item, index) => {
      // tslint:disable-next-line:no-string-literal
      if (item['assayWrapper']?.nativeElement?.contains(activeElement) || item?.nativeElement === activeElement) {
        focusItemIndex = index + focusDirection;

        if (focusItemIndex === this.lineItems.length) {
          focusItemIndex = 0;
        } else if (focusItemIndex < 0) {
          focusItemIndex = this.lineItems.length - 1;
        }
      }
    });

    this.focusLineItemByIndex(focusItemIndex);
  }

  focusLineItemByIndex(focusItemIndex: number) {
    this.lineItems.forEach((item: unknown, index) => {
      if (index === focusItemIndex) {
        // tslint:disable-next-line:no-string-literal
        let focusItem: ElementRef = item['assayWrapper'] as ElementRef;

        if (focusItem) {
          // Focus assay line item
          let assayComponent: AssayComponent;
          assayComponent = item as AssayComponent;
          assayComponent.selectAssay();
        } else {
          // focus service category
          focusItem = item as ElementRef;
          focusItem.nativeElement.focus();
        }

        window.requestAnimationFrame(() => {
          document.activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        });
      }
    });
  }

  /* Expand/collapse service category presentation */
  handleServiceCategoryAccordionToggle(serviceCategory: ServiceCategory) {
    serviceCategory.expanded = !serviceCategory.expanded;
  }

  /* Return boolean: collection contains releasable assays */
  hasReleasableAssays(assaysCollection: Assay[]) {
    return assaysCollection.filter((assay: Assay) => {
      return (
        assay.status !== AssayStatus.PENDING &&
        assay.status !== AssayStatus.RELEASED &&
        assay.status !== AssayStatus.CANCELED
      );
    }).length;
  }

  /*
  Get total number of releasable assays, compare to excluded:
  - all excluded = unchecked
  - some exluded = intermediate
  - none excluded = checked
  */
  getServiceCategoryExcludeTogglePresentation(assaysCollection: Assay[]) {
    const releasableAssays = [];
    const totalExcluded = [];
    let togglePresentation;

    assaysCollection.forEach((assay) => {
      if (assay.status !== AssayStatus.PENDING && assay.status !== AssayStatus.RELEASED) {
        releasableAssays.push(assay);
      }
      if (this.isAssayExcluded(assay)) {
        totalExcluded.push(assay);
      }
    });

    if (totalExcluded.length === releasableAssays.length) {
      togglePresentation = false;
    }

    if (totalExcluded.length < releasableAssays.length) {
      togglePresentation = 'INTERMEDIATE';
    }

    if (totalExcluded.length === 0) {
      togglePresentation = 'CHECKED';
    }

    return togglePresentation;
  }

  /*
  Get togglePresentation when clicked:
  - unchecked or intermediate = check all releasable service category assays
  - checked = uncheck all releasable service category assays
  */
  handleServiceCategoryExcludeToggle($event, assaysCollection: Assay[]) {
    const togglePresentation = this.getServiceCategoryExcludeTogglePresentation(assaysCollection);
    const releasableAssays = [];
    assaysCollection.forEach((assay) => {
      if (assay.status !== AssayStatus.PENDING && assay.status !== 'RELEASED') {
        releasableAssays.push(assay);
      }
    });

    releasableAssays.forEach((assay: Assay) => {
      if (!togglePresentation || togglePresentation === 'INTERMEDIATE') {
        // SET ALL TO CHECKED (INCLUDE ALL)
        const excludedAssayIndex = this.excludedAssays.findIndex(
          (excludedAssay: Assay) => excludedAssay.code === assay.code
        );
        if (excludedAssayIndex > -1) {
          this.excludedAssays.splice(excludedAssayIndex, 1);
        }
      } else if (togglePresentation === 'CHECKED') {
        // SET ALL TO UNCHECKED (EXCLUDE ALL)
        this.excludedAssays.push(assay);
      }
    });
  }

  getNextAccessionInQueue($event) {
    if ($event) {
      $event.preventDefault();
    }

    this.appStateService.accessionHeader = null;
    this.appStateService.accession = null;
    this.appStateService.currentAssay = of(null);
    this.accession = null;

    this.appStateService.loading = true;
    // TODO null check can go away when lab notes is enabled in prod
    this.labNotes?.closeLabNotesModal();

    this.queueService.advanceQueue(this.appStateService.queueNextUrl).subscribe((loadedAccessionInfo) => {
      this.appStateService.loading = false;

      if (loadedAccessionInfo.accession) {
        this.accession = loadedAccessionInfo.accession;
      }
    });
  }

  // Display accession released indicator
  isAccessionReleased() {
    return this.accession.isReleased;
  }

  ngOnDestroy() {
    this.selectedAssaySub.unsubscribe();

    if (this.accessionSub) {
      this.accessionSub.unsubscribe();
    }
    if (this.focusFirstAssaySub) {
      this.focusFirstAssaySub.unsubscribe();
    }
    this.appStateService.workQueue = null;
  }

  reloadAccession(accession: Accession) {
    this.appStateService.accession = null;
    this.appStateService.accessionHeader = null;
    this.appStateService.loading = true;

    this.accessionService.loadAccession(accession.accessionId).subscribe((updatedAccession) => {
      this.excludedAssays = [];
      this.appStateService.accession = updatedAccession;
      this.accession = updatedAccession;
      this.snackbar.hide();
    });
  }

  handleNextInQueueAction() {
    if (this.nextInQueueButton && !this.nextInQueueButton.nativeElement.disabled) {
      this.nextInQueueButton.nativeElement.click();
    }
  }

  releaseAccession() {
    const accession = this.accession;
    if (
      !this.appStateService.loading &&
      accession?._links?.release &&
      this.isAccessionReleasable(accession.assays) &&
      !this.labNotes?.hasUnsavedLabNotes
    ) {
      this.appStateService.loading = true;

      const includedAssayCodes: string[] = [];

      if (accession.assays?.length) {
        const includedAssays = accession.assays.filter(
          (assay) =>
            !this.excludedAssays.find((excludedAssay) => assay.code === excludedAssay.code) &&
            assay.status !== AssayStatus.PENDING &&
            assay.status !== AssayStatus.RELEASED &&
            assay.status !== AssayStatus.CANCELED
        );

        includedAssays.forEach((assay) => {
          includedAssayCodes.push(assay.code);
        });
      }

      this.releaseService.release(accession, includedAssayCodes).subscribe(() => {
        window.requestAnimationFrame(() => {
          if (this.canNavigateToNextInQueue()) {
            this.getNextAccessionInQueue(null);
          } else {
            this.router.navigate(['labs', this.appStateService.lab.id]);
          }
        });
      });
    }
  }

  private canNavigateToNextInQueue() {
    return (
      this.appStateService.queueWorkspace &&
      !this.appStateService.queueEmptyMessageVisible &&
      this.appStateService.queueNextUrl
    );
  }

  /* Add or remove an assay from excludedAssays collection */
  handleAssayExcludeToggle(assay: Assay) {
    let removed = false;
    this.excludedAssays.forEach((excludedAssay, index) => {
      if (assay.code === excludedAssay.code) {
        removed = true;
        this.excludedAssays.splice(index, 1);
      }
    });

    if (!removed) {
      this.excludedAssays.push(assay);
    }
  }

  isAssayExcluded(assay: Assay) {
    if (!this.excludedAssays?.length) {
      return false;
    } else {
      return this.excludedAssays.find((existingAssay) => existingAssay.code === assay.code) !== undefined;
    }
  }

  /* Enable/Disable accession release button */
  isAccessionReleasable(assaysCollection: Assay[]): boolean {
    const releasableAssays: Assay[] = [];

    assaysCollection.forEach((assay) => {
      if (
        assay.status !== AssayStatus.PENDING &&
        assay.status !== AssayStatus.RELEASED &&
        assay.status !== AssayStatus.CANCELED
      ) {
        releasableAssays.push(assay);
      } else if (this.isAssayExcluded(assay)) {
        // Remove assay from excludedAssays if it is excluded but not releasable
        this.excludedAssays.forEach((excludedAssay, index) => {
          if (assay.code === excludedAssay.code) {
            this.excludedAssays.splice(index, 1);
          }
        });
      }
    });

    return releasableAssays.length !== this.excludedAssays.length || this.accession.hasUnreleasedComments;
  }

  focusFirstAssay() {
    this.assays?.first?.selectAssay(true);
  }
}
