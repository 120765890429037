import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Event, NavigationStart, Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import {
  FlyoutComponent,
  FlyoutNavigationItem,
  HeaderComponent,
  Lab,
  Link,
  User,
  WorkQueue,
} from '@lims-common-ux/lux';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { WorkQueueToFlyoutNavigationItemPipe } from './workQueueToFlyoutNavigationItem/work-queue-to-flyout-navigation-item.pipe';

@Component({
  templateUrl: './lab.component.html',
  styleUrls: ['./lab.component.scss'],
})
export class LabComponent implements OnInit {
  labs: Lab[];
  currentLab: Lab;
  user: User;
  flyoutNavigation: FlyoutNavigationItem[] = [];
  accessionSearchLink: Link;
  currentWorkQueue: WorkQueue;

  @ViewChild('flyout', { static: false })
  flyout: FlyoutComponent;

  @ViewChild('header', { static: false })
  header: HeaderComponent;

  @ViewChild('helpModalContainer', { static: true })
  helpModalContainer: HelpModalComponent;

  get env() {
    return this.appStateService.env;
  }

  constructor(
    public appStateService: AppStateService,
    private route: ActivatedRoute,
    private router: Router,
    private workQueueToFlyoutNavigationItemPipe: WorkQueueToFlyoutNavigationItemPipe
  ) {}

  ngOnInit() {
    this.labs = this.appStateService.labs;
    this.user = this.appStateService.user;
    this.accessionSearchLink = this.appStateService.accessionSearchLink;
    this.currentWorkQueue = this.appStateService.workQueue;

    this.route.data.subscribe((data: { lab: Lab }) => {
      this.currentLab = data.lab;

      this.flyoutNavigation = [];

      if (this.appStateService.queues?.length) {
        this.appStateService.queues.forEach((wq) => {
          if (wq.type === 'FULL' || wq.type === 'PARTIAL' || wq.type === 'PARTIAL_VERIFIED' || wq.type === 'PARTIAL_NEEDS_REVIEW') {
            this.flyoutNavigation.push(this.workQueueToFlyoutNavigationItemPipe.transform(wq));
          }
        });
      }
    });

    this.router.events.subscribe((e: Event) => {
      this.currentWorkQueue = this.appStateService.workQueue;
      if (this.flyout && e instanceof NavigationStart) {
        this.flyout.closeSidebar();
      }
    });
  }

  handleUpdateLab(lab: Lab) {
    // clear any accession search values and errors
    this.header.reset();

    if (lab?.id === this.appStateService.lab?.id) {
      this.header.toggleDropdown();
    }

    // we are setting the current lab here to avoid some UI flickering on some labels and formatting due to the order
    // of how things get loaded. It will get 'reset' when the route finishes loading, but shouldn't actually change
    // anything.
    this.currentLab = this.labs.find((aLab) => lab.id === aLab.id);

    // navigate to the queue and clear any accession search values and errors
    this.router.navigate(['labs', lab.id, 'queues', 'FULL']);

    // Close help modal on lsb change
    this.helpModalContainer.close();
  }

  changeWorkspace(navItem: FlyoutNavigationItem) {
    this.flyout.closeSidebar();

    this.router.navigate(['/', 'labs', this.currentLab.id, 'queues', navItem.id]);
  }

  helpModalOpen() {
    this.helpModalContainer.open();
  }
}
